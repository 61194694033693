// @ts-strict-ignore
import { AfterViewInit, Component, Inject } from '@angular/core';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DialogRef, OMG_DIALOG_DATA } from '@app/shared/components/dialog';
import { PrivacyConsentStorageService } from '@app/shared/services/privacy-consent-storage.service';

export interface DialogData {
  noteCreatedById: string;
  patientId: Number;
  onProceed: Function;
}

@Component({
  selector: 'omg-timeline-restricted-note-modal',
  templateUrl: './timeline-restricted-note-modal.component.html',
  styleUrls: ['./timeline-restricted-note-modal.component.scss'],
})
export class TimelineRestrictedNoteModalComponent implements AfterViewInit {
  constructor(
    @Inject(OMG_DIALOG_DATA) public data: DialogData,
    public dialogRef: DialogRef<TimelineRestrictedNoteModalComponent, boolean>,
    private analytics: AnalyticsService,
    private consentService: PrivacyConsentStorageService,
  ) {}

  ngAfterViewInit() {
    this.analytics.track(AnalyticsEvent.PrivacyModalDisplayed, {
      noteCreatedById: this.data.noteCreatedById,
    });
  }

  proceed() {
    this.analytics.track(AnalyticsEvent.PrivacyModalClicked, {
      clickType: 'Proceed',
      noteCreatedById: this.data.noteCreatedById,
    });
    this.dialogRef.close();
    this.consentService.setConsentCookie(this.data.patientId);
    this.data.onProceed();
  }

  goBack() {
    this.analytics.track(AnalyticsEvent.PrivacyModalClicked, {
      clickType: 'Go Back',
      noteCreatedById: this.data.noteCreatedById,
    });
    this.dialogRef.close();
  }
}
