import { Component, Input, OnInit } from '@angular/core';

interface TagKeys {
  [key: string]: boolean;
}

@Component({
  selector: 'omg-note-icon',
  styleUrls: ['./note-icon.component.scss'],
  template: `
    <span *ngIf="!transferable" [ngClass]="iconClass"></span>
    <span
      omgSwapIcons
      *ngIf="transferable"
      [originalIcon]="iconClass"
      [iconToSwap]="swapIconClass"
    >
    </span>
  `,
})
export class NoteIconComponent implements OnInit {
  @Input() tags: Array<string> = [];
  @Input() small = false;
  @Input() transferable = false;
  @Input() noteType: string;
  @Input() inheritColor = true;
  iconClass: string;
  swapIconClass: string;

  ngOnInit() {
    this.iconClass = this.getIconClass();
    this.swapIconClass = `om-icon icon-ui-transfer${
      this.small ? '' : ' icon-large'
    }`;

    if (!this.inheritColor) {
      this.iconClass = this.color(this.iconClass, this.tags);
    }
  }

  private getIconClass(): string {
    return `om-icon icon-${this.mapKeysToCssClass(this.tags)}${
      this.small ? '' : '-large'
    }`;
  }

  private color(iconClass: string, tags: Array<string>): string {
    const keys: TagKeys = this.getTagKeys(tags);

    if (keys.results) {
      return `${iconClass} -red`;
    } else if (keys.order || keys.rx) {
      return `${iconClass} -yellow`;
    } else if (keys.travel || keys.clerical) {
      return `${iconClass} -green`;
    }

    return iconClass;
  }

  /* eslint-disable complexity */
  private mapKeysToCssClass(tags: Array<string>): string {
    const keys: TagKeys = this.getTagKeys(tags);

    switch (true) {
      case keys.message:
        return 'message';
      case keys.labReportCustom:
        return 'lab-report';
      case keys.encounter && keys.office:
        return 'visit-office';
      case keys.encounter && keys.phone:
        return 'visit-phone';
      case keys.encounter && keys.triage:
        return 'visit-triage';
      case keys.encounter && keys.video:
        return 'visit-video';
      case (keys.encounter && keys['mental health']) ||
        this.noteType === 'Misc Protected':
        return 'visit-mental';
      case keys.encounter && keys.email:
        return 'message';
      case keys.results && (keys.laboratory || keys.test || keys.lab):
        return 'lab-report';
      case keys.results && keys.imaging:
        return 'imaging';
      case keys.results && keys.internal:
        return 'procedure';
      case keys.order:
        return 'lab-order';
      case keys.rx || keys.prescription:
        return 'med';
      case keys.travel && keys.questionnaire:
        return 'survey';
      case keys.clerical &&
        ((keys.request && keys.questionnaire) || keys.release):
        return 'clerical';
      case this.noteType === 'Misc Task' || this.noteType === 'Misc Clerical':
        return 'misc-task';
      case this.noteType === 'Official Letter':
        return 'letter';
      default:
        return 'visit-misc';
    }
  }

  /* eslint-enable complexity */

  private getTagKeys(tags: Array<string>): TagKeys {
    return tags.reduce(
      (accumulator, current) => ({
        ...accumulator,
        [current]: true,
      }),
      {},
    );
  }
}
