// @ts-strict-ignore
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, shareReplay, switchMap } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { ChangeRx } from '@app/features/change-rx/shared/change-rx.type';
import { ChangeRxSubmitAction } from '@app/features/change-rx/store/change-rx.reducer';
import { ChangeRxSelectors } from '@app/features/change-rx/store/change-rx.selectors';

import { Renewal } from '../../shared/renewals.type';
import { RenewalSelectors } from '../../store';

@Component({
  selector: 'omg-renewal-list',
  templateUrl: './renewal-list.component.html',
  styleUrls: ['./renewal-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenewalListComponent implements OnInit {
  renewals$: Observable<Renewal[]>;
  changeRxs$: Observable<ChangeRx[]>;
  cartComplete$: Observable<boolean>;
  changeRxEnabled$: Observable<boolean>;
  changeRxSubmitAction$: Observable<ChangeRxSubmitAction>;
  chartReadabilityFeatureEnabled$: Observable<boolean>;

  constructor(
    private renewalSelectors: RenewalSelectors,
    private changeRxSelectors: ChangeRxSelectors,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  ngOnInit() {
    this.renewals$ = this.renewalSelectors.pendingRenewals;
    this.changeRxEnabled$ = this.launchDarklyService
      .variation$(FeatureFlagNames.medsChangeRx, false)
      .pipe(shareReplay());

    this.changeRxs$ = this.changeRxEnabled$.pipe(
      filter(Boolean),
      switchMap(() => this.changeRxSelectors.entities),
    );

    this.cartComplete$ = this.renewalSelectors.cartComplete;

    this.chartReadabilityFeatureEnabled$ = this.launchDarklyService
      .variation$(FeatureFlagNames.chartReadability, true)
      .pipe(shareReplay());
  }
}
