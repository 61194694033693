import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Checkbox } from 'primeng/checkbox';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

@Component({
  selector: 'omg-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent),
    },
  ],
  // NOTE: OnPush causes some issues with the underlying PrimeNg component
  // - In particular the disabled state sometimes doesn't change until the
  //   next change dectection check.
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() plainLabel = false;
  @Input() labelStyleClass = '';
  @Input() tabindex: number;
  @Input() disabled = false;
  @Input() required = false;
  @Output() check = new EventEmitter<boolean>();

  @ViewChild(Checkbox, { static: true }) private checkbox: Checkbox;

  private _value = false;

  chartReadabilityFeatureEnabled: boolean;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(private launchDarklyService: LaunchDarklyService) {}

  ngOnInit(): void {
    this.chartReadabilityFeatureEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.chartReadability,
      true,
    );
  }

  @Input()
  get value() {
    return this._value;
  }

  set value(value: any) {
    if (this._value !== value) {
      this._value = value;
      this.onChange(value);
    }
  }

  get versionClass() {
    return {
      'checkbox-v1': !this.chartReadabilityFeatureEnabled,
      'checkbox-v2': this.chartReadabilityFeatureEnabled,
    };
  }

  focus() {
    this.checkbox.focus();
  }

  /* istanbul ignore next */
  writeValue(value: any) {
    this._value = value;
  }

  /* istanbul ignore next */
  registerOnChange(fn: (value: any) => {}) {
    this.onChange = fn;
  }

  /* istanbul ignore next */
  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  /* istanbul ignore next */
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.checkbox.disabled = isDisabled;
  }
}
