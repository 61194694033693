<div class="omg-dialog-container"
     style="width: 560px;"
     data-cy="delete-vital-modal"
     omgTheme>
  <div omgDialogTitleDirective>
    <h5>Sensitive Notes from Therapy <span title="Sensitive Note"
            class="om-icon icon-shield-keyhole"></span></h5>

    <button omgButton
            variant="none"
            class="om-icon icon-close clickable"
            (click)="goBack()"></button>
  </div>

  <div omgDialogContentDirective>
    <p class="padding-normal">
      To review clinical updates from therapy including treatment plans and progress,
      please click “Go Back” and look in the A&P sections of the Problem Card(s).
      If you need to access sensitive notes from therapy sessions for clinical, billing, or
      legal purposes, please click “Access Note”.
    </p>
  </div>

  <div omgDialogActionsDirective>
    <button omgButton
            variant="primary"
            (click)="goBack()">
      Go Back
    </button>
    <button omgButton
            variant="link"
            (click)="proceed()">
      Access Note
    </button>
  </div>
</div>
