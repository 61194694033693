import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Summary } from '@app/features/summaries/shared/summaries.type';
import { Note } from '@app/modules/note/shared/note.type';
import { Todo } from '@app/modules/todo/shared/todo.type';

type HistoryNote = Summary | Note;

@Component({
  selector: 'omg-note-title',
  templateUrl: './note-title.component.html',
  styleUrls: ['./note-title.component.scss'],
})
export class NoteTitleComponent {
  @Input() type: string = 'HistoryNote';
  @Input() iconTags: Array<string> = [];
  @Input() noteType: string = '';
  @Input() historyNote: HistoryNote;
  @Input() todo: Todo;
  @Input() isExpanded: boolean = true;
  @Input() titleText: string;
  @Input() editableTitle: boolean = false;
  @Input() form?: UntypedFormGroup;
  @Input() requiresPrivacyConsent: boolean = false;
  @Input() isAttachingEnabled: boolean = false;
  @Input() canBePushedToWorkspace: boolean = false;

  @Output() closeNoteHeader = new EventEmitter<void>();

  get isHistoryNote() {
    return this.type == 'HistoryNote';
  }
}
