import { SearchAfterCursor } from '@app/core/search/open-search/types/search-after-cursor';
import { S3Pointer } from '@app/modules/aws/shared/aws-session.type';
import { Measurement } from '@app/modules/measurements/shared/measurements.type';
import { NoteDocument, NoteFax } from '@app/modules/note/shared/note.type';
import { LabOrderType } from '@app/modules/orders/shared/lab-order.type';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { set } from '@app/utils';

export interface TimelineItem {
  id: number;
  subject: string;
  timelineDate: string;
  authorDetails: TimelineAuthorDetails;
  draft: boolean;
  messagesCount: number;
  doctype?: string;
  databaseId: number;
  labOrderId?: number;
  summaryId?: number;
  patientId?: number;
  type: string;
  signedAt: string;
  signed: boolean;
  content: TimelineItemContent;
  s3Pointers: S3Pointer[];
  comments: TimelineItemComment[];
  author: string;
  documents: NoteDocument[];
  sentAt?: string;
  signedBy: string;
  tags?: string[];
  notification: string;
  messages: string[];
  contentType?: string;
  createdAt: string;
  updatedAt: string;
  clinical: boolean;
  authorIsPatient?: boolean;
  url?: string;
  body?: string;
  office?: string;
  noteType?: string;
  incompleteTodo?: string;
  todo?: TimelineItemTodo;
  signedByDetails?: TimelineAuthorDetails;
  faxes?: NoteFax[];
  attachableFiles?: S3Pointer[];
  isProtected?: boolean;
  appointment?: TimelineItemAppointment;
  procedureSummary?: string;
  procedureCodes?: string;
  completedAt?: string;
  procedureType?: string;
  indication?: string;
  additionalIndications?: string;
  timelineBillingUnitsDisplay?: string;
  reviewedAt: string;
  reviewedBy: string;
  measurements?: Measurement[];
  orderingOffice?: OrderingOffice;
  labOrderTypes?: LabOrderType[];
  indications?: any[];
  stat?: boolean;
  fasting?: boolean;
  noteToPhleb?: string;
  cc?: string;
  status?: string;
  labName?: string;
  labAccountNumber?: string;
  collectionDate?: string;
  collectionTime?: string;
  wasFasting?: boolean;
  collectionNotes?: string;
  tubeInventory?: string;
  collectedBy?: string;
  scheduledDate?: string;
  complexProcedures?: any;
  complexData?: any;
  dynamicForm?: any;
  procedureFormTypeId?: string;
  procedureFormType?: string;
  paginationData?: PaginationData;
}

export interface PaginationData {
  /**
   * Indicates the index of the item within the timeline.
   * 0-based (i.e. first item has `index: 0`).
   */
  index: number;

  /**
   * Indicates the position of the item within the timeline.
   * 1-based (i.e. first item has `position: 1`).
   */
  position: number;

  /**
   * Total number of items available in the result set.
   */
  total: number;
}

export interface TimelineItemTodo extends Todo {
  assigneeId: number;
  assigneeType: string;
  contactId: number;
  createdAt: string;
  deferUntil: string;
  finishedById: number;
  isComplete: boolean;
  keywords: string;
  lockedAt: string;
  lockedById: number;
  patientId: number;
  priority: number;
  taskTemplateId: number;
  updatedAt: string;
}

export interface TimelineAuthorDetails {
  nickname: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  id: number;
}

export interface TimelineItemContent {
  id: number;
  text: string;
  createdAt: string;
  updatedAt: string;
  author: string;
  notificationTarget: string;
  sentAt?: string;
}

export interface TimelineItemComment {
  author: string;
  createdAt: string;
  draft: boolean;
  id: number;
  recipient: string;
  s3Pointers: S3Pointer[];
  sentAt: string;
  text: string;
  updatedAt: string;
}

export interface TimelineItemAppointment {
  startAt: string;
  endAt: string;
  state: string;
  type: string;
  reason: string;
}

export type TimelineFilterNames = {
  [FilterType in TimelineFilterTypes]: string;
};

export type TimelineElasticSearchFilters = {
  [FilterType in TimelineFilterTypes]: Array<any>;
};

interface OrderingOffice {
  city: string;
  phone: string;
  fax: string;
}

export enum TimelineFilterTypes {
  All = 'all',
  Clinical = 'clinical',
  MyNotes = 'myNotes',
  Clerical = 'clerical',
  Encounters = 'encounters',
  Results = 'results',
  Messages = 'messages',
  Tasks = 'tasks',
}

export const timelineFilterNames: TimelineFilterNames = {
  [TimelineFilterTypes.All]: 'All',
  [TimelineFilterTypes.Clinical]: 'Clinical',
  [TimelineFilterTypes.MyNotes]: 'My Notes',
  [TimelineFilterTypes.Clerical]: 'Clerical',
  [TimelineFilterTypes.Encounters]: 'Encounters',
  [TimelineFilterTypes.Results]: 'Results',
  [TimelineFilterTypes.Messages]: 'Messages',
  [TimelineFilterTypes.Tasks]: 'Tasks',
};

export const timelineElasticSearchFilters = {
  [TimelineFilterTypes.All]: [],
  [TimelineFilterTypes.Clinical]: [{ term: { clinical: true } }],
  [TimelineFilterTypes.MyNotes]: [{ term: { internal_user_id: 'PROFILE_ID' } }],
  [TimelineFilterTypes.Clerical]: [{ term: { clinical: false } }],
  [TimelineFilterTypes.Encounters]: [{ term: { tags: 'encounter' } }],
  [TimelineFilterTypes.Results]: [{ term: { tags: 'results' } }],
  [TimelineFilterTypes.Messages]: [
    {
      bool: {
        should: [
          { term: { doctype: 'conversation' } },
          { term: { doctype: 'patient_timeline_post' } },
        ],
      },
    },
  ],
  [TimelineFilterTypes.Tasks]: [{ exists: { field: 'incomplete_todo' } }],
};

export const getElasticSearchFilter = (
  filterType: TimelineFilterTypes,
  profileId: string,
): any => {
  switch (filterType) {
    case TimelineFilterTypes.MyNotes:
      return [
        set(
          'term.internal_user_id',
          profileId,
          timelineElasticSearchFilters[filterType][0],
        ),
      ];
    default:
      return timelineElasticSearchFilters[filterType];
  }
};

// Restricted Note Configuration
// Supporting a first iteration of 'break the glass' functionality in the patient chart
// Mapping below maps a Note type (by name) to a list of unrestricted 1Life roles (lowercase, snake_cased)
export const NOTE_TYPE_SECURE_ROLE: Record<string, string[]> = {
  'Mental Health': ['mental_health'],
};

export interface TimelineSearchParameters {
  patientId: number;
  profileId: number;
  term: string;
  filter: TimelineFilterTypes;
  cursor: SearchAfterCursor | undefined;
}

export interface TimelineSearchResponse {
  items: any[];
  totalItems: number;
  cursor: SearchAfterCursor | undefined;
}
