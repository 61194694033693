import { Component, Input, OnInit } from '@angular/core';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

@Component({
  selector: 'omg-section-title-redesign',
  templateUrl: './section-title-redesign.component.html',
  styleUrls: ['./section-title-redesign.component.scss'],
})
export class SectionTitleRedesignComponent implements OnInit {
  @Input() heading: string = '';

  chartReadabilityFeatureEnabled: boolean;

  constructor(private launchDarklyService: LaunchDarklyService) {}

  ngOnInit(): void {
    this.chartReadabilityFeatureEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.chartReadability,
      true,
    );
  }
}
