// @ts-strict-ignore
import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ProfileSelectors } from '@app/core';
import { DialogService } from '@app/shared';
import { PrivacyConsentStorageService } from '@app/shared/services/privacy-consent-storage.service';

import {
  DialogData,
  TimelineRestrictedNoteModalComponent,
} from './components/timeline-restricted-note-modal/timeline-restricted-note-modal.component';
import { NOTE_TYPE_SECURE_ROLE, TimelineItem } from './shared/timeline.type';

/**
 * The TimelinePrivacyService is an abstraction of the functionality
 * required for the "break-the-glass" feature. Version 1, will obfuscate from the user on screen
 * even while the data is available in the browser.  Future versions should expand on the methods
 * below for a more robust solution
 */
@Injectable({
  providedIn: 'root',
})
export class TimelinePrivacyService {
  constructor(
    private profile: ProfileSelectors,
    private dialogService: DialogService,
    private privacyConsentStorageService: PrivacyConsentStorageService,
  ) {}

  /**
   * Requires the characteristics of a restricted note to be met
   * Could be extended to a backend related solution
   * @param item
   * @returns
   */
  itemRequiresPrivacyConsent(item: TimelineItem): boolean {
    return this.isNoteRestricted(item);
  }

  /**
   * Captures the launching of the "break the glass" modal to get consent before
   * viewing restricted notes
   *
   * Confiugration lives in timeline.type.ts
   *
   * Note this is part 1 of a greater effort to secure MH notes
   * @param item
   * @param onValid Callback
   */
  checkNoteRestrictions(item: TimelineItem, onValid: Function) {
    this.validateRestrictedNote(
      item,
      () => {
        onValid();
      },
      () => {
        this.dialogService.open(TimelineRestrictedNoteModalComponent, {
          autoFocus: true,
          data: {
            noteCreatedById: item.authorDetails.id.toString(),
            patientId: item.patientId,
            onProceed: () => {
              onValid();
            },
          } as DialogData,
        });
      },
      this.profile,
    );
  }

  /**
   * Helper method to get the unrestricted roles for a particular note
   * @param timelineItem
   * @returns
   */
  private getUnrestrictedRoles(timelineItem: TimelineItem): string[] {
    if (
      timelineItem.noteType &&
      timelineItem.doctype &&
      timelineItem.doctype === 'note' &&
      NOTE_TYPE_SECURE_ROLE[timelineItem.noteType] !== undefined
    ) {
      return NOTE_TYPE_SECURE_ROLE[timelineItem.noteType];
    }
    return [];
  }

  /**
   * Helper method to validate if a note is restricted given the profile role
   * @param item
   * @param onUnrestricted
   * @param onRestricted
   * @param profile
   */
  private validateRestrictedNote(
    item: TimelineItem,
    onUnrestricted: Function,
    onRestricted: Function,
    profile: ProfileSelectors,
  ) {
    const roleObservables$ = this.getUnrestrictedRoles(item).map(
      (role: string) => {
        return profile.hasRole(role);
      },
    );

    roleObservables$.push(
      of(this.privacyConsentStorageService.isConsentSaved(item.patientId)),
    );

    combineLatest(roleObservables$)
      .pipe(
        take(1),
        map(([...roleChecks]) => roleChecks.some(Boolean)),
      )
      .subscribe(hasUnrestrictedRole =>
        hasUnrestrictedRole ? onUnrestricted() : onRestricted(),
      );
  }

  /**
   * Helper method to denote if the timeline item is a protected note
   * @param timelineItem
   * @returns
   */
  private isNoteRestricted(timelineItem: TimelineItem): boolean {
    return (
      timelineItem.doctype === 'note' &&
      NOTE_TYPE_SECURE_ROLE[timelineItem.noteType] !== undefined
    );
  }
}
