<ng-container>
  <div #noteTitle
       class="note-title"
       omgTheme
       om-layout
       om-layout-fill
       om-layout-align="space-between center">
    <div om-layout
         om-layout-fill
         om-layout-align="start stretch"
         class="note-title-content">
      <div omgCollapse
           [expanded]="isExpanded"
           #noteTitleCollpaseRef="omgCollapse"
           om-layout
           om-layout-fill
           om-layout-gutter
           om-layout-align="space-between center">
        <div om-layout
             om-layout-fill
             om-layout-gutter
             om-layout-align="space-between center"
             [formGroup]="form">
          <ng-template [ngIf]="isHistoryNote"
                       [ngIfElse]="postTitle">
            <omg-note-icon [tags]="iconTags"
                           [inheritColor]="false"
                           [noteType]="noteType"
                           *ngIf="!canBePushedToWorkspace; else timelineIcon">
            </omg-note-icon>
            <ng-template #timelineIcon>
              <omg-timeline-item-icon [item]="historyNote"></omg-timeline-item-icon>
            </ng-template>

            <span *ngIf="isHistoryNote; else postTitle"
                  om-flex
                  om-layout-align="start"
                  om-layout-gutter-mini
                  om-layout="vertical"
                  [title]="historyNote.subject">
              <omg-editable-label *ngIf="editableTitle; else summaryNoteTitle1"
                                  formControlName="subject"
                                  om-flex>
              </omg-editable-label>

              <ng-template #summaryNoteTitle1>
                <span class="summary-note-title">{{ titleText }}</span>
                <span *ngIf="requiresPrivacyConsent"
                      class="om-icon icon-shield-keyhole"></span>
              </ng-template>
              <omg-note-history-redesign [note]="historyNote"
                                         [todo]="todo"></omg-note-history-redesign>
            </span>
          </ng-template>

          <ng-template #postTitle>
            <omg-note-icon [tags]="iconTags"
                           [noteType]="noteType"
                           [small]="true"></omg-note-icon>
            <span om-flex
                  om-layout="vertical"
                  om-layout-align="start">
              <omg-editable-label *ngIf="editableTitle; else summaryNoteTitle2"
                                  name="subject"
                                  class="-wrapped-text"
                                  formControlName="topic"
                                  placeholder="Set message topic"
                                  omgStopEvent
                                  om-flex>
              </omg-editable-label>
              <ng-template #summaryNoteTitle2>
                <span class="summary-note-title">{{ titleText }}</span>
              </ng-template>
            </span>
          </ng-template>
        </div>

        <div om-layout
             om-layout-gutter
             om-layout-align="start center"
             class="action-icons">
          <ng-content om-layout></ng-content>
        </div>
      </div>
    </div>
  </div>
</ng-container>
