<ng-container>
  <div omgTheme
       class="section-title-container"
       om-layout
       om-layout-align="space-between center">
    <div omgTheme
         om-layout>
      <strong class="section-title">{{ heading }}</strong>
    </div>
    <div om-layout
         class="section-title-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-container>
