import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import { CollapseDirective } from '../collapse';

@Component({
  selector: 'omg-card-title',
  templateUrl: './card-title.component.html',
  styleUrls: ['./card-title.component.scss'],
})
export class CardTitleComponent implements OnInit {
  @Input() heading: string;
  @Input() smallFontSize = false;
  @Input() isExpandable = false;
  @Input() collapseProvider: CollapseDirective;
  @Input() badgeCount$: Observable<number>;

  private chartReadabilityFeatureEnabled: boolean;

  constructor(private launchDarklyService: LaunchDarklyService) {}

  ngOnInit() {
    this.chartReadabilityFeatureEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.chartReadability,
      true,
    );
  }

  @ViewChild('cardTitle', { static: true }) cardTitle: ElementRef;
  get tooltipText() {
    return `${
      this.collapseProvider.expanded ? 'Collapse' : 'Expand'
    } ${this.heading.toLowerCase()}`;
  }

  get titleVersion() {
    return {
      'card-title': true,
      'old-experience': !this.chartReadabilityFeatureEnabled,
      'new-experience': this.chartReadabilityFeatureEnabled,
    };
  }
}
