<ng-container>
  <div om-layout="vertical"
       class="expandable-nested-table-content">
    <div om-layout
         om-layout-fill
         om-layout-align="space-between center"
         class="list-title-header">
      <span class="list-header-title">{{ listTitle }}</span>
      <span omgTooltip
            class="om-icon clickable icon-add-new -gray -small"
            omgStopEvent="click"
            (click)="onAdd()"></span>
    </div>

    <div *ngIf="expandAddPanel"
         class="addPanel">
      <ng-content></ng-content>
    </div>

    <div *ngIf="listItems">
      <div *ngFor="let listItem of listItems; first as isFirst">
        <div om-layout
             om-layout-fill
             om-layout-align="space-between center"
             class="list-item-content"
             [ngClass]="{ 'no-border': isFirst }">
          <ng-template [ngIf]="!listItem.icon"
                       [ngIfElse]="iconStyle">
            <div om-layout="vertical"
                 om-layout-fill>
              <p class="list-item-name">{{ listItem.name }}</p>
              <p *ngIf="listItem.secondaryData"
                 class="list-item-caption">
                {{ listItem.secondaryData }}
              </p>
            </div>
          </ng-template>
          <ng-template #iconStyle>
            <omg-note-icon class="icon"
                           [tags]="listItem.icon.tags"
                           [noteType]="listItem.icon.noteType"></omg-note-icon>
            <p class="icon-list-item-name"
               om-layout-fill>{{ listItem.name }}</p>
          </ng-template>

          <div class="remove-btn">
            <span omgTooltip
                  om-layout-align="end"
                  tooltipText="Remove Attachment"
                  tooltipPosition="left"
                  class="om-icon clickable icon-dismiss -gray"
                  omgStopEvent="click"
                  (click)="remove(listItem)"
                  data-cy="remove-attachment-button"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
