// @ts-strict-ignore
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';

import {
  mapTimelineItemToAnalyticsData,
  mapTimelineItemToRoute,
} from '../../shared/timeline.mapper';
import { TimelineItem } from '../../shared/timeline.type';
import { TimelinePrivacyService } from '../../timeline-privacy.service';

@Component({
  selector: 'omg-timeline-item-icon',
  templateUrl: './timeline-item-icon.component.html',
  styleUrls: ['./timeline-item-icon.component.scss'],
})
export class TimelineItemIconComponent implements OnInit {
  @Input() item: TimelineItem;
  @Input() isSelected = false;
  @Input() smallIcon = false;

  iconSizeClass: string;

  constructor(
    private router: Router,
    private analytics: AnalyticsService,
    private timelinePrivacyService: TimelinePrivacyService,
  ) {}

  ngOnInit() {
    this.iconSizeClass = this.iconClass;
  }

  pushItemToWorkspace(item: TimelineItem) {
    if (this.timelinePrivacyService.itemRequiresPrivacyConsent(item)) {
      this.timelinePrivacyService.checkNoteRestrictions(item, () => {
        this.displayItem(item);
      });
    } else {
      this.displayItem(item);
    }
  }

  displayItem(item: TimelineItem) {
    this.trackPushToWorkspace(item);
    this.router.navigateByUrl(mapTimelineItemToRoute(item));
  }

  private get iconClass(): string {
    return this.smallIcon ? '' : ' icon-large';
  }

  private trackPushToWorkspace(item: TimelineItem): void {
    this.analytics.track(
      AnalyticsEvent.ClinicalTimelineObjectOpened,
      mapTimelineItemToAnalyticsData(item, 'Push'),
    );
  }
}
