import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface ExpandableNestedTableListItemIcon {
  tags?: string[];
  noteType?: string;
}

export interface ExpandableNestedTableListItem {
  icon?: ExpandableNestedTableListItemIcon;
  name: string;
  secondaryData?: string;
  onRemove?: () => void;
}

@Component({
  selector: 'omg-expandable-nested-table',
  templateUrl: './expandable-nested-table.component.html',
  styleUrls: ['./expandable-nested-table.component.scss'],
})
export class ExpandableNestedTableComponent {
  @Input() listTitle: string;
  @Input() listItems?: ExpandableNestedTableListItem[];
  @Input() collapsible: boolean = false;
  @Input() customAddAction: boolean = false;

  @Output() addEvent = new EventEmitter();

  expandAddPanel: boolean = false;

  onAdd() {
    if (!this.customAddAction) {
      this.expandAddPanel = true;
    } else {
      this.addEvent.emit();
    }
  }

  remove(listItem: ExpandableNestedTableListItem) {
    listItem.onRemove();
  }

  closeExpandPanel() {
    this.expandAddPanel = false;
  }
}
