<div *ngIf="note">
  <form [omgForm]="noteFormModel"
        [formGroup]="noteFormModel.form">
    <ng-template [ngIf]="!chartReadabilityFeatureEnabled"
                 [ngIfElse]="noteComponentTitle">
      <omg-card-title>
        <div omgCollapse
             #noteCollapse="omgCollapseProvider"
             [expanded]="note.totalComments > 0">
          <omg-note-header [note]="note$ | ngrxPush"
                           [todo]="todo$ | ngrxPush"
                           [editable]="noteHasEditableTitle$ | ngrxPush"
                           (closeNoteHeader)="onCloseNote()"
                           [form]="noteFormModel.form"
                           [commentsCollapseRef]="noteCollapse"></omg-note-header>
        </div>
      </omg-card-title>
      <omg-card-body data-cy="note-card">
        <div omgScrollingContainer
             #scrollableContainer>
          <!-- Comments -->
          <omg-expanded [collapseProvider]="noteCollapse">
            <omg-comments [commentable]="commentable"
                          (commentAdded)="onCommentUpdate('add')"
                          (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
          </omg-expanded>

          <div om-layout="vertical"
               om-layout-gutter>
            <omg-task-assignment *ngIf="noteHasIncompleteTodo$ | ngrxPush"
                                 (focusOn)="trackAssignmentSelected()"
                                 (focusOut)="trackAssignmentUnselected()"
                                 (clicked)="highPriorityClicked()"
                                 [todo]="todo$">
            </omg-task-assignment>
            <omg-task-template-selector *ngIf="noteHasIncompleteTodo$ | ngrxPush"
                                        [inProgress]="todoIsUpdating$ | ngrxPush"
                                        [todo$]="todo$"></omg-task-template-selector>
          </div>

          <omg-ml-note-retitle-feedback *ngIf="note.mlNoteSuggestion && !note.mlNoteSuggestion.mlFeedback"
                                        [note$]="note$">
          </omg-ml-note-retitle-feedback>

          <!-- Note body -->
          <div class="padding-normal"
               *ngIf="showRichTextNoteBodyEditor">
            <omg-chart-text-box templateType="text"
                                [scrollingContainer]="scrollableContainer"
                                [control]="noteFormModel.form.get('body')"
                                quillFormat="html"
                                placeholderText="Type message here..."
                                [insertionEventProps]="{
                                  component: note.noteType.name,
                                  subcomponent: 'Note Body'
                                }"
                                (focusTextBox)="trackMessageSelected()"
                                (blurTextBox)="trackMessageUnselected()">
            </omg-chart-text-box>
          </div>


          <div *ngIf="showSimpleNoteBodyEditor"
               class="padding-normal">
            <label>Note Body
              <omg-chart-text-box [control]="noteFormModel.form.get('body')"
                                  [insertionEventProps]="{
                                    component: note.noteType.name,
                                    subcomponent: 'Note Body'
                                  }">
              </omg-chart-text-box>
            </label>
          </div>

          <div class="padding-normal note-body"
               omgTheme
               *ngIf="showReadOnlyNoteBody && !showSimpleNoteBodyEditor"
               [ngClass]="getClassForNoteBody(note.noteType.name)"
               [innerHTML]="note.body">
          </div>

          <div class="padding-normal"
               *ngFor="let attachment of noteFormModel.get('attachments').value">
            <omg-file-link [url]="attachment.viewingPath"
                           [text]="attachment.name"
                           [deletable]="isAttachable"
                           (delete)="onDeleteAttachment(attachment)"></omg-file-link>
          </div>

          <!-- Documents -->
          <div class="padding-normal"
               *ngFor="let doc of noteFormModel.get('documents').value; index as index"
               om-layout="vertical"
               om-layout-gutter>
            <div om-layout-gutter>
              <omg-document-summary [documentId]="doc.id"></omg-document-summary>
              <omg-problem-selector (trackSelectionRemoved)="selectionRemoved()"
                                    (trackSelectionAdded)="selectionAdded()"
                                    (trackTypeaheadOpened)="typeaheadOpened()"
                                    (trackTypeaheadClosed)="typeaheadClosed()"
                                    [documentId]="doc.id"></omg-problem-selector>
              <omg-file-link *ngIf="!doc.forLab"
                             [key]="doc.awsKey"
                             [encode]="false"
                             [bucket]="doc.awsBucket"
                             text="Download File"></omg-file-link>

              <button *ngIf="showRecordProcedureButton$(doc.id) | ngrxPush"
                      (click)="toggleProcedureCaptureForm(doc.id)"
                      omgButton
                      variant="secondary">{{ getProcedureCaptureButtonText$(doc.id) | ngrxPush }}</button>
            </div>

            <omg-historical-procedure-capture *ngIf="canRecordHistoricalProcedure"
                                              [documentId]="doc.id"
                                              [attemptedNoteSubmit]="isConfirmingProcedure">
            </omg-historical-procedure-capture>

            <omg-lab-result *ngIf="doc.forLab"
                            [awsKey]="doc.awsKey"
                            [awsBucket]="doc.awsBucket"
                            [labDataId]="doc.parentId"
                            [patientId]="patient?.id"></omg-lab-result>

            <omg-pdf-viewer class="workspace-column"
                            *ngIf="onIsPdfDocument(doc)"
                            [key]="doc.pdfKey"
                            [bucket]="doc.pdfBucket"></omg-pdf-viewer>
          </div>

          <omg-transmission-details *ngIf="note.faxes.length > 0"
                                    [history]="noteFormInfo.transmissionDetails"></omg-transmission-details>

          <omg-form-state when="saveError">
            <ng-template let-change>
              <div class="om-messages"
                   *ngFor="let msg of change.error">
                {{ msg }}
              </div>
            </ng-template>
          </omg-form-state>
          <div class="padding-normal om-messages -warning"
               *ngIf="attachError">{{ attachError }}</div>
          <div class="padding-normal">
            <div class="banner -instructional"
                 data-cy="next-steps-instructions-banner"
                 *ngIf="nextStepsEnabled$ | ngrxPush">
              This task type has follow-up steps. Fill in the details you can above, then click "Next Step" to
              reassign or repeat.
            </div>
          </div>
        </div>
      </omg-card-body>
    </ng-template>

    <ng-template #noteComponentTitle>
      <omg-note-title [iconTags]="note.noteType.tags"
                      [noteType]="note.noteType.name"
                      [isExpanded]="note.totalComments > 0"
                      [historyNote]="note$ | ngrxPush"
                      [todo]="todo$ | ngrxPush"
                      [editableTitle]="noteHasEditableTitle$ | ngrxPush"
                      [form]="noteFormModel.form">
        <omg-collapse-toggle [collapseProvider]="noteTitleCollpaseRef"
                             om-layout
                             om-layout-gutter-mini
                             om-layout-align="space-between center"
                             class="collapse-with-flex">
          <omg-collapsed [collapseProvider]="noteTitleCollpaseRef"
                         disableAnimation="true">
            <span class="om-icon icon-comment clickable"></span>
          </omg-collapsed>
          <omg-expanded [collapseProvider]="noteTitleCollpaseRef"
                        disableAnimation="true">
            <span class="om-icon icon-comment-filled clickable"></span>
          </omg-expanded>
          <span>{{note.totalComments}}</span>
        </omg-collapse-toggle>

        <span omgTooltip
              tooltipPosition="left"
              omgStopEvent="click"
              data-cy="timeline-tooltip-text"
              [ngClass]="toolTipIcon()"
              [tooltipText]="toolTipText()"
              class="expand-timeline-icon"
              *ngIf="noteHasDocuments && note.totalComments > 0"
              (click)="setExpanded()"></span>

        <span class="om-icon icon-close clickable"
              omgStopEvent="click"
              (click)="onClose()">
        </span>
      </omg-note-title>
      <omg-card-body data-cy="note-card">
        <div omgScrollingContainer
             #scrollableContainer>
          <!-- Comments -->
          <omg-expanded [collapseProvider]="noteTitleCollpaseRef">
            <omg-comments [commentable]="commentable"
                          (commentAdded)="onCommentUpdate('add')"
                          (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
          </omg-expanded>

          <div om-layout="vertical"
               om-layout-gutter>
            <omg-task-assignment *ngIf="noteHasIncompleteTodo$ | ngrxPush"
                                 (focusOn)="trackAssignmentSelected()"
                                 (focusOut)="trackAssignmentUnselected()"
                                 (clicked)="highPriorityClicked()"
                                 [todo]="todo$">
            </omg-task-assignment>
            <omg-task-template-selector *ngIf="noteHasIncompleteTodo$ | ngrxPush"
                                        [inProgress]="todoIsUpdating$ | ngrxPush"
                                        [todo$]="todo$"></omg-task-template-selector>
          </div>

          <omg-ml-note-retitle-feedback *ngIf="note.mlNoteSuggestion && !note.mlNoteSuggestion.mlFeedback"
                                        [note$]="note$">
          </omg-ml-note-retitle-feedback>

          <!-- Note body -->
          <div class="padding-normal"
               *ngIf="showRichTextNoteBodyEditor">
            <omg-chart-text-box templateType="text"
                                [scrollingContainer]="scrollableContainer"
                                [control]="noteFormModel.form.get('body')"
                                quillFormat="html"
                                placeholderText="Type message here..."
                                [insertionEventProps]="{
                                  component: note.noteType.name,
                                  subcomponent: 'Note Body'
                                }"
                                (focusTextBox)="trackMessageSelected()"
                                (blurTextBox)="trackMessageUnselected()">
            </omg-chart-text-box>
          </div>


          <div *ngIf="showSimpleNoteBodyEditor"
               class="padding-normal">
            <label>Note Body
              <omg-chart-text-box [control]="noteFormModel.form.get('body')"
                                  [insertionEventProps]="{
                                    component: note.noteType.name,
                                    subcomponent: 'Note Body'
                                  }">
              </omg-chart-text-box>
            </label>
          </div>

          <div class="padding-normal note-body"
               omgTheme
               *ngIf="showReadOnlyNoteBody && !showSimpleNoteBodyEditor"
               [ngClass]="getClassForNoteBody(note.noteType.name)"
               [innerHTML]="note.body">
          </div>

          <div class="padding-normal"
               *ngFor="let attachment of noteFormModel.get('attachments').value">
            <omg-file-link [url]="attachment.viewingPath"
                           [text]="attachment.name"
                           [deletable]="isAttachable"
                           (delete)="onDeleteAttachment(attachment)"></omg-file-link>
          </div>

          <!-- Documents -->
          <div class="padding-normal"
               *ngFor="let doc of noteFormModel.get('documents').value; index as index"
               om-layout="vertical"
               om-layout-gutter>
            <div om-layout-gutter>
              <omg-document-summary [documentId]="doc.id"></omg-document-summary>
              <omg-problem-selector (trackSelectionRemoved)="selectionRemoved()"
                                    (trackSelectionAdded)="selectionAdded()"
                                    (trackTypeaheadOpened)="typeaheadOpened()"
                                    (trackTypeaheadClosed)="typeaheadClosed()"
                                    [documentId]="doc.id"></omg-problem-selector>
              <omg-file-link *ngIf="!doc.forLab"
                             [key]="doc.awsKey"
                             [encode]="false"
                             [bucket]="doc.awsBucket"
                             text="Download File"></omg-file-link>

              <button *ngIf="showRecordProcedureButton$(doc.id) | ngrxPush"
                      (click)="toggleProcedureCaptureForm(doc.id)"
                      omgButton
                      variant="secondary">{{ getProcedureCaptureButtonText$(doc.id) | ngrxPush }}</button>
            </div>

            <omg-historical-procedure-capture *ngIf="canRecordHistoricalProcedure"
                                              [documentId]="doc.id"
                                              [attemptedNoteSubmit]="isConfirmingProcedure">
            </omg-historical-procedure-capture>

            <omg-lab-result *ngIf="doc.forLab"
                            [awsKey]="doc.awsKey"
                            [awsBucket]="doc.awsBucket"
                            [labDataId]="doc.parentId"
                            [patientId]="patient?.id"></omg-lab-result>

            <omg-pdf-viewer class="workspace-column"
                            *ngIf="onIsPdfDocument(doc)"
                            [key]="doc.pdfKey"
                            [bucket]="doc.pdfBucket"></omg-pdf-viewer>
          </div>

          <omg-transmission-details *ngIf="note.faxes.length > 0"
                                    [history]="noteFormInfo.transmissionDetails"></omg-transmission-details>

          <omg-form-state when="saveError">
            <ng-template let-change>
              <div class="om-messages"
                   *ngFor="let msg of change.error">
                {{ msg }}
              </div>
            </ng-template>
          </omg-form-state>
          <div class="padding-normal om-messages -warning"
               *ngIf="attachError">{{ attachError }}</div>
          <div class="padding-normal">
            <div class="banner -instructional"
                 data-cy="next-steps-instructions-banner"
                 *ngIf="nextStepsEnabled$ | ngrxPush">
              This task type has follow-up steps. Fill in the details you can above, then click "Next Step" to
              reassign or repeat.
            </div>
          </div>
        </div>
      </omg-card-body>
    </ng-template>

    <omg-card-footer>
      <omg-collapse #noteActionBarCollapse="omgCollapse">
        <omg-expanded *ngIf="isConfirmingNextStep">
          <form [formGroup]="form">
            <div class="action-bar"
                 om-layout
                 data-cy="expanded-next-step-confirmation"
                 om-layout-align="space-between center">
              <omg-dropdown class="om-dropdown"
                            data-cy="next-step-dropdown"
                            [options]="nextSteps$ | ngrxPush"
                            [flex]="true"
                            [placeholder]="'Select next step'"
                            formControlName="selectedNextStep">
              </omg-dropdown>
              <span om-layout
                    om-layout-gutter
                    om-layout-align="space-between center">
                <button omgButton
                        variant="flat"
                        [disabled]="todoIsUpdating"
                        (click)="toggleNextStepSelection()">Cancel</button>
                <button type="button"
                        omgButton
                        variant="primary"
                        data-cy="expanded-next-step-btn"
                        [disabled]="todoIsUpdating"
                        (click)="onNextStepSelected()">Finish & Create</button>
              </span>
            </div>
          </form>
        </omg-expanded>
        <omg-expanded *ngIf="isDeleting">
          <div class="action-bar -warning"
               om-layout
               om-layout-align="space-between center">
            <b>Permanently delete this note?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleDeleting()">
                Never mind
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="onDelete()">
                Delete Note
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-expanded *ngIf="isConfirmingProcedure">
          <div class="action-bar"
               om-layout
               om-layout-align="space-between center">
            <b>Review without saving procedure?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleProcedureConfirm()">
                Cancel
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="doFinishNote(todo)">
                Review
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-expanded *ngIf="isConfirmingSignature">
          <div class="action-bar -warning"
               om-layout
               om-layout-align="space-between center"
               data-cy="note-confirm-signature">
            <b>Are you sure?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleConfirm()">
                Never mind
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="doFinishNote(todo)">
                Sign Note
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-expanded *ngIf="isRedacting">
          <div class="action-bar -warning"
               om-layout
               om-layout-align="space-between center">
            <b>Permanently redact this note?</b>
            <span om-layout
                  om-layout-gutter
                  om-layout-align="space-between center">
              <button omgButton
                      variant="flat"
                      (click)="onToggleRedact()">
                Never mind
              </button>
              <button type="button"
                      omgButton
                      variant="primary"
                      (click)="onRedactNote()">
                Redact
              </button>
            </span>
          </div>
        </omg-expanded>

        <omg-template-insertion-action-bar *ngIf="showTemplateInsertionBar()"
                                           [collapseRef]="noteActionBarCollapse"
                                           [index]="noteTemplatesIndex"
                                           (apply)="onApplyNoteTemplate($event)">
        </omg-template-insertion-action-bar>


        <omg-expanded *ngIf="isRecategorizing">
          <omg-recategorize-document-action-bar [index]="recategorizeSearchIndex"
                                                (cancel)="onCancelRecategorize()"
                                                (apply)="onRecategorizeDocument($event)">
          </omg-recategorize-document-action-bar>
        </omg-expanded>

        <!-- Default action bar -->
        <omg-collapsed>
          <div class="action-bar"
               [hidden]="hasTodoError | ngrxPush"
               om-layout>
            <div om-layout
                 om-layout-align="start center"
                 om-layout-gutter
                 om-layout-fill>
              <omg-form-state>
                <ng-template let-change>
                  <div om-layout
                       om-layout-gutter>
                    <button *ngIf="(noteHasIncompleteTodo$ | ngrxPush) && !(nextStepsEnabled$ | ngrxPush)"
                            omgButton
                            variant="primary"
                            [ngClass]="{ '-processing': change.state === 'saving' }"
                            type="submit"
                            [disabled]="change.state === 'saving' || (formUploading$ | ngrxPush) || (todoIsUpdating$ | ngrxPush)"
                            data-cy="finish-note-btn"
                            (click)="onFinishNote(todo)">{{ (change.state === 'saving' || (todoIsUpdating$ | ngrxPush))
                      ? 'Saving...' : (finishLabel$ |
                      ngrxPush)
                      }}</button>
                    <omg-split-button *ngIf="nextStepsEnabled$ | ngrxPush"
                                      variant="primary"
                                      data-cy="finish-menu"
                                      [defaultButtonDataCy]="'next-step-button'"
                                      [dropdownCaratDataCy]="'next-step-dropdown-carat'"
                                      [items]="nextStepOptions.items"
                                      [label]="(inProgress$ | ngrxPush) ? nextStepOptions.inProgressLabel : nextStepOptions.label"
                                      [disabled]="(inProgress$ | ngrxPush)"
                                      (labelClick)="nextStepOptions.command()"></omg-split-button>
                    <button omgButton
                            *ngIf="note.noteType.name === 'Misc Clinical'"
                            variant="link"
                            (click)="openVMTHelpModal()"
                            [disabled]="change.state === 'saving'"
                            [omgTrack]="{
                              event: 'click',
                              action: 'Create HelpOut Request'
                            }"
                            data-cy="create-vmt-help-request-link">Create HelpOut Request</button>
                  </div>
                  <ng-container *ngIf="!(noteHasIncompleteTodo$ | ngrxPush)">
                    <ng-container *ngTemplateOutlet="secondaryActionsButton">
                    </ng-container>
                  </ng-container>
                </ng-template>
              </omg-form-state>
            </div>
            <div om-layout
                 om-layout-align="end center"
                 om-layout-gutter-large
                 om-layout-fill>
              <ng-container *ngIf="noteHasIncompleteTodo$ | ngrxPush">
                <ng-container *ngTemplateOutlet="secondaryActionsButton">
                </ng-container>
              </ng-container>
              <omg-file-upload-button *ngIf="isAttachable"
                                      om-layout
                                      om-layout-align="space-between center"
                                      (fileChosen)="onAddAttachments($event)">
              </omg-file-upload-button>
            </div>
            <ng-template #secondaryActionsButton>
              <omg-split-button *ngIf="(secondaryActionButtonParams$ | ngrxPush) as secondaryActionButton"
                                [items]="secondaryActionButton.items"
                                [label]="(inProgress$ | ngrxPush) ? secondaryActionButton.inProgressLabel : secondaryActionButton.label"
                                [disabled]="(inProgress$ | ngrxPush)"
                                (labelClick)="secondaryActionButton.command()"></omg-split-button>
            </ng-template>
            <div class="action-bar -error"
                 *ngIf="noteHasIncompleteTodo$ | ngrxPush"
                 [hidden]="!(hasTodoError | ngrxPush)">
              {{todoError | ngrxPush}}
            </div>
          </div>
        </omg-collapsed>
      </omg-collapse>
    </omg-card-footer>
  </form>
</div>

<omg-printable-note *ngIf="patient && note"
                    [hasDocuments]="hasDocuments"
                    [patient]="patient"
                    [note]="note">
</omg-printable-note>
