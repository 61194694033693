<div om-layout="vertical"
     om-layout-align="center"
     *ngIf="!isSelected; else selectedIcon"
     [ngSwitch]="item.doctype">
  <span class="clickable"
        omgSwapIcons
        omgStopEvent="click"
        data-cy="timeline-push-item-to-workspace"
        [originalIcon]="'om-icon -gray icon-message' + iconSizeClass"
        [iconToSwap]="'om-icon icon-ui-transfer' + iconSizeClass"
        *ngSwitchCase="'patient_timeline_post'"
        (click)="pushItemToWorkspace(item)">
  </span>
  <omg-note-icon class="clickable"
                 omgStopEvent="click"
                 *ngSwitchCase="'note'"
                 [inheritColor]="false"
                 [small]="smallIcon"
                 [tags]="item.tags"
                 [noteType]="item.noteType"
                 [transferable]="true"
                 (click)="pushItemToWorkspace(item)">
  </omg-note-icon>
  <span class="clickable"
        *ngSwitchCase="'lab_order'"
        omgStopEvent="click"
        omgSwapIcons
        [originalIcon]="'om-icon -yellow icon-lab-order' + iconSizeClass"
        [iconToSwap]="'om-icon icon-ui-transfer' + iconSizeClass"
        (click)="pushItemToWorkspace(item)">
  </span>
  <span class="clickable"
        *ngSwitchCase="'admission'"
        omgStopEvent="click"
        omgSwapIcons
        [originalIcon]="'fa-light fa-hospital' + iconSizeClass"
        [iconToSwap]="'om-icon icon-ui-transfer' + iconSizeClass"
        (click)="pushItemToWorkspace(item)">
  </span>
  <span class="clickable"
        omgSwapIcons
        omgStopEvent="click"
        data-cy="timeline-push-item-to-workspace"
        [originalIcon]="'om-icon -red icon-procedure' + iconSizeClass"
        [iconToSwap]="'om-icon icon-ui-transfer' + iconSizeClass"
        *ngSwitchCase="'procedure_interaction'"
        (click)="pushItemToWorkspace(item)">
  </span>
  <span [class]="'om-icon -gray icon-message' + iconSizeClass"
        *ngSwitchCase="'conversation'"></span>
</div>
<ng-template #selectedIcon>
  <span [class]="'om-icon icon-ui-transfer' + iconSizeClass"
        om-layout-align="center">
  </span>
</ng-template>
