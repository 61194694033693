<ng-container>
  <div [omgPopoverTriggerFor]="noteHistoryPopover"
       triggerOn="hover"
       class="history-title-container">
    <p class="display-history-text">{{ history[0] }}</p>
  </div>
  <omg-popover placement="bottom"
               [enabled]="history.length > 1"
               #noteHistoryPopover>
    <div class="problem-attachment-popover-content">
      <div *ngFor="let historyItem of history; first as isFirst">
        <div class="popover-history-item"
             [ngClass]="{ 'no-border': isFirst }">{{ historyItem }}</div>
      </div>
    </div>
  </omg-popover>
</ng-container>
