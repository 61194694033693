<div [ngClass]="versionClass">
  <div *ngIf="label"
       class="label"
       (click)="focus()">{{label}}</div>
  <p-dropdown #dropdown
              autoWidth="false"
              [autoDisplayFirst]="autoDisplayFirst"
              [options]="options"
              [optionLabel]="optionLabel"
              [optionValue]="optionKey"
              [placeholder]="placeholder"
              [group]="group"
              [filter]="filter"
              [filterBy]="filterBy"
              [filterPlaceholder]="filterPlaceholder"
              [tabindex]="tabindex"
              [disabled]="disabled"
              [readonly]="calculatedReadOnly"
              [required]="required"
              [style]="inlineStyle"
              [ngStyle]="inlineStyle"
              [styleClass]="styleClass"
              [panelStyleClass]="panelStyleClass"
              [appendTo]="appendTo"
              (onShow)="onShow($event)"
              (onHide)="onHide($event)"
              (onFocus)="onFocus()"
              (onChange)="onUpdate()"
              [(ngModel)]="value"
              [dropdownIcon]="dropdownIconClass">
  </p-dropdown>
</div>
