<ng-container *ngIf="post | ngrxPush as post">
  <div *ngIf="docked"
       sticky-card-container
       docked
       [ngClass]="{ 'minimized': isMinimized }">
    <omg-card>
      <omg-messaging [post]="post"
                     [todo]="todo | ngrxPush"
                     [todoReassignment]="todoReassignment"
                     [docked]="true"
                     [patientWarnings]="patientWarnings | ngrxPush"
                     [postFormModel]="postFormModel"
                     [inProgressMessage]="inProgressMessage"
                     (closeMessagingComponent)="onClose()"
                     (send)="onSend($event)"
                     (deleteDraft)="onDeleteDraft(post, inProgressMessage)"
                     (minimizeChange)="minimizeChange($event)"></omg-messaging>
    </omg-card>
  </div>
</ng-container>
<ng-container *ngIf="!docked">
  <div *ngIf="post | ngrxPush as post; else loading">
    <omg-messaging [post]="post"
                   [todo]="todo | ngrxPush"
                   [todoReassignment]="todoReassignment"
                   [docked]="false"
                   [patientWarnings]="patientWarnings | ngrxPush"
                   [postFormModel]="postFormModel"
                   [inProgressMessage]="inProgressMessage"
                   (closeMessagingComponent)="onClose()"
                   (send)="onSend($event)"
                   (deleteDraft)="onDeleteDraft(post, inProgressMessage)"></omg-messaging>
  </div>

  <ng-template #loading>
    <omg-card-title heading="Message">
      <div om-layout
           om-flex
           om-layout-align="end center">
        <span class="clickable om-icon icon-close"
              (click)="onClose()"
              data-cy="close-loading-message"></span>
      </div>
    </omg-card-title>
    <omg-feature-loading-spinner></omg-feature-loading-spinner>
  </ng-template>
</ng-container>
