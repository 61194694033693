<ng-container *ngIf="changeRxs$ | ngrxPush as changeRxs">
  <div class="data-table"
       *ngIf="changeRxs.length > 0">
    <omg-section-title *ngIf="chartReadabilityFeatureEnabled$ | ngrxPush; else newTitle">
      Change Requests ({{changeRxs.length}})</omg-section-title>
    <ng-template #newTitle>
      <omg-section-title-redesign heading="Change Requests"></omg-section-title-redesign>
    </ng-template>
    <omg-section-title [altBackgroundColor]="true">
      <div om-layout
           om-layout-fill
           om-layout-gutter
           om-layout-align="space-between">
        <span om-flex>MEDICATION</span>
        <span om-flex>ASSIGNED TO</span>
        <span om-flex>REQUEST TYPE</span>
      </div>
    </omg-section-title>
  </div>
  <omg-change-rx-list-item *ngFor="let changeRx of changeRxs"
                           [changeRx]="changeRx"></omg-change-rx-list-item>
</ng-container>
<ng-container *ngIf="renewals$ | ngrxPush as renewals">
  <div class="data-table"
       *ngIf="renewals.length > 0">
    <div *ngIf="changeRxEnabled$ | ngrxPush">
      <omg-section-title *ngIf="!chartReadabilityFeatureEnabled$ | ngrxPush; else redesignedSectionTitle">
        Renewal Requests ({{renewals.length}})
      </omg-section-title>
      <ng-template #redesignedSectionTitle>
        <omg-section-title-redesign heading="Renewal Requests"></omg-section-title-redesign>
      </ng-template>
    </div>
    <omg-section-title [altBackgroundColor]="true">
      <div om-layout
           om-layout-fill
           om-layout-gutter
           om-layout-align="space-between">
        <span om-flex>MEDICATION</span>
        <span om-flex>ASSIGNED TO</span>
        <span om-flex>REQUESTED BY</span>
      </div>
    </omg-section-title>
  </div>
  <omg-renewal-list-item *ngFor="let renewal of renewals"
                         [renewal]="renewal"></omg-renewal-list-item>
</ng-container>
<omg-ready-for-checkout *ngIf="!(cartComplete$ | ngrxPush)"
                        [isListView]="true"></omg-ready-for-checkout>
